// Amplify
:root {
  --amplify-colors-teal-10: #3f51bf !important;
  --amplify-colors-teal-20: #3f51bf !important;
  --amplify-colors-teal-40: #3f51bf !important;
  --amplify-colors-teal-60: #3f51bf !important;
  --amplify-colors-teal-80: #3f51bf !important;
  --amplify-colors-teal-100: #3f51bf !important;
}

// Here you can add other styles

.header {
  min-height: 4rem;
  border: 0;

  .input-group .form-control {
    --cui-body-color: var(--cui-body-color-dark);
    --cui-input-placeholder-color: var(--cui-secondary-color-dark);
  }
}

.sidebar-brand-full {
  margin-left: 3px;
}

.sidebar-header {
  .nav-underline-border {
    --cui-nav-underline-border-link-padding-x: 1rem;
    --cui-nav-underline-border-gap: 0;
  }

  .nav-link {
    display: flex;
    align-items: center;
    min-height: calc(4rem + 1px); // stylelint-disable-line function-disallowed-list
  }
}

.sidebar-toggler {
  @include ltr-rtl("margin-left", auto);
}

.sidebar-narrow,
.sidebar-narrow-unfoldable:not(:hover) {
  --cui-sidebar-padding-x: #{$spacer};

  .sidebar-toggler {
    display: none;
  }

  .sidebar-nav {
    --cui-sidebar-nav-padding-x: #{$spacer * .5};
  }
}

.sidebar-nav {
  @include transition(padding .15s);
}

.card,
.dropdown-menu,
.footer,
.sidebar {
  box-shadow: rgb(50 50 93 / 2%) 0 2px 5px -1px, rgb(0 0 0 / 5%) 0 1px 3px -1px;
}

@if $enable-dark-mode {
  @include color-mode(dark) {
    .sidebar {
      background-color: var(--cui-body-bg) !important; // stylelint-disable-line
      background-image: none !important; // stylelint-disable-line
      border: 0;
    }
  }
}

// Domeseer Styles
.header {
  --cui-header-bg: #ffffff;
  --cui-header-color: #333333;
  --cui-header-hover-color: #333333;
  --cui-header-toggler-color: #333333;
  --cui-body-color-dark: #252b36;
  --cui-input-placeholder-color: #252b36;
}

.form-label {
  font-size: .88rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
}


.toast-body {
  color: white;
}

td.text-truncate {
  max-width: 5rem;
}

.simpledropdown .actionDropdown .dropdown-toggle,
.simpledropdown .actionDropdown .dropdown-toggle:hover,
.simpledropdown .actionDropdown .dropdown-toggle:active {
  background: none;
  border: none;
}

.simpledropdown .actionDropdown .dropdown-toggle:after {
  display: none;
}

.accordion-button:not(.collapsed),
.accordion-button:focus {
  outline: none;
  border-color: transparent;
  box-shadow: none;
  background-color: transparent;
}
.accordion-button::after {
  width: 11px;
  height: 11px;
  border-radius: 100%;
  background-color: var(--bs-danger);
  background-image: none !important;
}
.accordion-button.collapsed::after {
  background-color: var(--bs-gray-300);
}

.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
